<!-- publishedJournals -->
<template>
  <div
    v-loading="loading"
    class="publishedJournals common-select"
    :class="screenClass"
  >
    <div class="header">
      <div class="total">
        共计<span>{{ totalDataLength }}</span
        >条记录
      </div>
      <div class="select">
        <span>统计时间：</span>
        <el-select
          v-model="chooseTime"
          placeholder="请选择"
          :data-track="`增值服务-文献聚合-整期发表期刊-统计时间-${chooseTimeLabel}`"
        >
          <el-option
            v-for="item in timeOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </div>
    </div>
    <div class="line"></div>
    <div class="details">
      <div v-if="tableData.length > 0">
        <div
          class="item-content"
          v-for="(item, index) in tableData"
          :key="index"
        >
          <div class="item" @click="toDetails(item)">
            <img
              :src="
                item.magCoverId
                  ? `${coverUrl}?pictureId=${item.magCoverId}`
                  : noImg
              "
              alt=""
            />
            <div class="right-content">
              <div class="title-time">
                <div class="title" v-if="item.magName">{{ item.magName }}</div>
                <div class="time">
                  <span class="year" v-if="item.year"
                    ><span>{{ item.year }}</span
                    >年</span
                  >
                  <span class="issue" v-if="item.issue"
                    >第<span>{{ item.issue }}</span
                    >期</span
                  >
                </div>
              </div>
              <div class="en-title" v-if="item.enName">{{ item.enName }}</div>
              <div class="org-publishTime">
                <div class="org">主办单位：{{ item.org || '--' }}</div>
                <div class="publishTime">
                  出版时间：{{ item.publishDate || '--' }}
                </div>
              </div>
              <div class="issn">ISSN：{{ item.issn || '--' }}</div>
              <div class="cn">CN：{{ item.cn || '--' }}</div>
              <div class="influence">
                影响因子：
                <span>知网：{{ item.influence1 || '--' }}；</span>
                <span>万方：{{ item.influence2 || '--' }}；</span>
                <span>百度：{{ item.influence3 || '--' }}；</span>
              </div>
            </div>
          </div>
        </div>
        <Pagination
          :show-total="false"
          :init-obj="tablePageObj"
          @onChangePageData="getTableData"
        />
      </div>
      <div v-else class="no-data">暂无数据</div>
    </div>
  </div>
</template>
<script>
import { Get, ProjectConfig } from 'kjqk-commons/src/common/index.js'
const api = ProjectConfig.contentAggregation
import { mapState } from 'vuex'
import Pagination from '@comp/pagination'
export default {
  name: 'PublishedJournals',
  data() {
    return {
      loading: true,
      noImg: require('@assets/img/noImg.jpg'),
      coverUrl: api.GetPictureStream.url,
      totalDataLength: 0,
      chooseTime: 7,
      timeOptions: [
        {
          value: 7,
          label: '最近一周'
        },
        {
          value: 30,
          label: '最近一个月'
        },
        {
          value: 92,
          label: '最近三个月'
        },
        {
          value: 183,
          label: '最近半年'
        }
      ],
      tableData: [],
      tablePageObj: {
        page: 1,
        size: 10
      }
    }
  },
  props: {},
  components: { Pagination },
  computed: {
    ...mapState('incrementService', ['magId']),
    screenClass() {
      return document.body.clientWidth >= 1920 ? 'class-1920' : 'class-1280'
    },
    chooseTimeLabel() {
      let text = ''
      text = this.timeOptions.filter((item) => {
        return item.value === this.chooseTime
      })[0].label
      return text
    }
  },
  watch: {
    chooseTime() {
      if (this.magId) {
        this.getTableData()
      }
    },
    magId() {
      this.getTableData()
    }
  },
  mounted() {
    if (this.magId) {
      this.getTableData()
    } else {
      this.loading = false
    }
  },
  methods: {
    getTableData(pageParams = {}) {
      const { page = 1, size = 10 } = pageParams
      const { startTime = '', endTime = '' } = this.getTimePeriod(
        this.chooseTime
      )
      const params = {
        id: this.magId,
        // id: 9416,
        page: page - 1,
        size,
        startTime,
        endTime,
        sort: 'publishDate,DESC'
      }
      let url = api.GetIssues.url
      Get(url, { params }).then((res) => {
        if (res && res.data && res.data.status === 0) {
          this.loading = false
          const { data } = res.data
          const { content = [], totalElements = 0 } = data
          this.tablePageObj = {
            page,
            size,
            total: totalElements
          }
          this.tableData = content
          this.totalDataLength = totalElements
        }
      })
    },
    toDetails(item) {
      let routeData = this.$router.resolve({
        path: 'publishedJournalsDetails',
        query: {
          columnId: this.$route.query.columnId,
          magId: item.magId,
          year: item.year,
          issue: item.issue,
          coverId: item.magCoverId
        }
      })
      window.open(routeData.href, '_blank')
    },
    // 开始和结束时间字符串的生成
    getTimePeriod(dayNum = 0) {
      const nowDate = new Date()
      const date = new Date(nowDate)
      date.setDate(date.getDate() - dayNum)
      const startTime = this.changeDateToString(date)
      const endTime = this.changeDateToString(nowDate)
      return {
        startTime,
        endTime
      }
    },
    // 处理date为字符串
    changeDateToString(date = new Date()) {
      const seperator = '-'
      const year = date.getFullYear()
      let month = date.getMonth() + 1
      let strDate = date.getDate()
      if (month >= 1 && month <= 9) {
        month = '0' + month
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = '0' + strDate
      }
      const currentDate = year + seperator + month + seperator + strDate
      return currentDate
    }
  }
}
</script>
<style lang="scss">
.common-select {
  .el-select {
    font-size: 12px;
    color: #333333;
    width: 100px;
    .el-input__inner {
      height: 28px;
    }
  }
}
</style>
<style lang="scss" scoped>
.publishedJournals {
  .header {
    padding: 14px;
    font-size: 14px;
    color: #404040;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .total {
      span {
        margin: 0 4px;
      }
    }
    .select {
      span {
        margin-right: 4px;
      }
    }
  }
  .line {
    background: #f0f2f5;
    height: 10px;
  }
  .details {
    .item-content {
      border-bottom: 1px solid #e4e4e4;
      padding: 10px 0;
    }
    .item {
      display: flex;
      align-items: center;
      width: 100%;
      img {
        width: 112px;
        height: 158px;
        object-fit: contain;
        cursor: pointer;
      }
      .right-content {
        font-size: 14px;
        font-family: Microsoft YaHei;
        color: #333333;
        line-height: 22px;
        margin-left: 30px;
        width: 100%;
        .title-time {
          display: flex;
          align-items: center;
          color: #4054af;
          font-size: 18px;
          cursor: pointer;
          .title {
            font-weight: bold;
          }
          .time {
            margin-left: 10px;
            .year,
            .issue {
              span {
                margin: 0 4px;
              }
            }
          }
        }
        .org-publishTime {
          margin-top: 20px;
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }
}
.class-1920 {
  .details {
    padding: 10px 20px;
    .item-content {
      display: inline-block;
      width: 49%;
      margin: 0 3px 0 10px;
    }
  }
}
.class-1280 {
  .details {
    padding: 10px 50px;
  }
}
.no-data {
  margin: 10px auto;
  text-align: center;
  font-size: 14px;
}
</style>
